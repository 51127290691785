<div class="control">
	<div class="form-group mb-8">
		<div
			class="position-relative"
			[class.default]="!labelName"
			[formGroup]="form"
		>
			<input
				#element
				[id]="'i-' + id"
				[type]="!showPass ? typeInput : 'text'"
				[attr.name]="name"
				[formControlName]="name"
				[placeholder]="placeholder || ''"
				[setValue]="inputModel"
				[mask]="inputMask"
				(keyup)="onChange($event, element.value)"
				maxlength="50"
				type="text"
				class="form-control form-input-ghr"
				placeholder="name@example.com"
			/>
			<label *ngIf="labelName" class="form-label" [for]="'i-' + id">{{
				labelName
			}}</label>
			<button
				*ngIf="typeInput == 'password'"
				(click)="toggleInputPass()"
				type="button"
				class="show-pass"
				[class.active]="showPass"
				data-input="name"
			>
				<figure>
					<img
						[src]="
							'../../../../assets/images/' +
							(showPass ? 'show.png' : 'hide.svg')
						"
						alt="Arrow Down"
						title="Arrow Down"
					/>
				</figure>
			</button>
		</div>

		<div *ngIf="callout" class="form-text">{{ callout }}</div>
		<app-control-error
			[form]="form"
			[name]="name"
			[labelName]="labelName"
		></app-control-error>
	</div>
</div>
