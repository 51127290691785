import { Component, OnInit } from '@angular/core'
import { AuthService } from 'src/app/services/auth/auth.service'
import { Location } from '@angular/common'

@Component({
	selector: 'app-not-found',
	templateUrl: './not-found.component.html',
	styleUrls: ['./not-found.component.scss'],
})
export class NotFoundComponent implements OnInit {
	constructor(
		private authService: AuthService,
		private location: Location,
	) {}

	ngOnInit(): void {
	}

	backToPage(): void {
		this.location.back()
	}
}
