import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { FormGroup } from '@angular/forms'

@Component({
	selector: 'app-checkbox',
	templateUrl: './checkbox.component.html',
	styleUrls: ['./checkbox.component.scss'],
})
export class CheckboxComponent implements OnInit {
	@Input() form: FormGroup = new FormGroup(Input())
	@Input() id?: string
	@Input() name: string | number | null = ''
	@Input() value?: boolean | string
	@Input() disabled?: boolean
	@Input() labelName: string = ''
	@Input() typeCheck: string = 'primary'
	@Input() inputModel: any
	@Input() changeModel?: Function

	constructor() {}

	ngOnInit(): void {}

	fnChangeModel(event: any): void {
		if (this.changeModel) this.changeModel(event)
	}
}
