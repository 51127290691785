import { Component, Input, OnInit } from '@angular/core'
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap'
import { ToastrService } from 'ngx-toastr'

@Component({
	selector: 'app-modal-default',
	templateUrl: './modal-status.component.html',
	styleUrls: ['./modal-status.component.scss'],
})
export class ModalStatusComponent implements OnInit {
	@Input() callbackConfirmation?: Function
	@Input() title?: string
	@Input() desc?: string
	@Input() btnName?: string = 'Continuar'
	@Input() type?: string = 'success'
	@Input() idForm?: string

	constructor(
		public activeModal: NgbActiveModal,
		private toast: ToastrService
	) {}

	ngOnInit(): void {}

	close() {
		this.activeModal.close()
	}

	copyToClipboard(text: string) {
		const textarea = document.createElement('textarea')
		textarea.textContent = text
		textarea.style.position = 'fixed'
		document.body.appendChild(textarea)
		textarea.select()
		try {
			this.toast.success(
				'ID copiado para área de transferência!',
				'Copiado'
			)
			return document.execCommand('copy')
		} catch (ex) {
			console.warn('Copy to clipboard failed.', ex)
			this.toast.error('A cópia do texto falhou!', 'Falha')
			return false
		} finally {
			document.body.removeChild(textarea)
		}
	}

	submit(el: any) {
		if (this.callbackConfirmation) this.callbackConfirmation(el)
	}
}
