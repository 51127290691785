import { Component, Input, OnInit } from '@angular/core'
import { FormGroup } from '@angular/forms'

@Component({
	selector: 'app-checkbox-custom',
	templateUrl: './checkbox-custom.component.html',
	styleUrls: ['./checkbox-custom.component.scss'],
})
export class CheckboxCustomComponent implements OnInit {
	@Input() form: FormGroup = new FormGroup(Input())
	@Input() id?: string
	@Input() name: string | number | null = ''
	@Input() value?: boolean | string
	@Input() disabled?: boolean
    @Input() labelName: string = ''
	@Input() inputModel: any
	@Input() changeModel?: Function

	constructor() {}

	ngOnInit(): void {}

	fnChangeModel(event: any): void {
		if (this.changeModel) this.changeModel(event)
	}
}
