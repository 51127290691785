<div class="modal-body">
	<div
		*ngIf="type === 'success'"
		class="swal2-icon swal2-success swal2-icon-show"
		style="display: flex"
	>
		<div
			class="swal2-success-circular-line-left"
			style="background-color: rgb(255, 255, 255)"
		></div>
		<span class="swal2-success-line-tip"></span>
		<span class="swal2-success-line-long"></span>
		<div class="swal2-success-ring"></div>
		<div
			class="swal2-success-fix"
			style="background-color: rgb(255, 255, 255)"
		></div>
		<div
			class="swal2-success-circular-line-right"
			style="background-color: rgb(255, 255, 255)"
		></div>
	</div>

	<div
		*ngIf="type === 'error'"
		class="swal2-icon swal2-error swal2-icon-show"
		style="display: flex"
	>
		<span class="swal2-x-mark">
			<span class="swal2-x-mark-line-left"></span>
			<span class="swal2-x-mark-line-right"></span>
		</span>
	</div>

	<h3 *ngIf="title">{{ title }}</h3>
	<p *ngIf="desc">{{ desc }}</p>
	<h5
		*ngIf="idForm"
		(click)="copyToClipboard(idForm)"
		title="Clique aqui para copiar"
		id="user-id-form-journey"
	>
		{{ idForm }}
	</h5>
	<div class="modal-footer">
		<button
			type="button"
			class="btn btn-xs btn-primary-ghr"
			(click)="close()"
			id="close-form-journey"
		>
			{{ btnName }}
		</button>
	</div>
</div>
