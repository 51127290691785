import { Component, Input, OnInit } from '@angular/core'
import { FormGroup } from '@angular/forms'

@Component({
    selector: 'app-control-error',
    templateUrl: './control-error.component.html',
    styleUrls: ['./control-error.component.scss']
})
export class ControlErrorComponent implements OnInit {
    @Input() form: FormGroup = new FormGroup(Input())
    @Input() name: string | number | null = ''
    @Input() labelName?: string = ''

    constructor() {
    }

    ngOnInit(): void {
    }

}
