import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { HelpersService } from '../helpers/helpers.service'
import { environment } from '../../../environments/environment'
import { Store } from '@ngrx/store'
import { AppState } from '../../state/app-state'
import { Callbacks } from '../../models/callbacks'
import { finalize } from 'rxjs/operators'
import * as moment from 'moment'
import { AddUser } from '../../state/user/user.action'

@Injectable({
	providedIn: 'root',
})
export class MeService {
	constructor(
		private http: HttpClient,
		private helpers: HelpersService,
		private store: Store<AppState>
	) {}

	me(id: any): void {
		this.http
			.get<any>(`${environment.api}/my/${id}`)
			.pipe()
			.subscribe(
				(response) => {
					const { data } = response
					const me = {
						...data,
						id,
					}
					this.store.dispatch(AddUser(me))
				},
				(error) => {
					console.error(error)
				}
			)
	}

	meUpdate(id: any, data: any, callbacks: Callbacks): void {
		this.http
			.patch<any>(`${environment.api}/my/${id}`, data)
			.pipe(
				finalize(() => {
					if (callbacks.fnFinalized) callbacks.fnFinalized()
				})
			)
			.subscribe(
				(data) => {
					callbacks.fnSuccess(data)
				},
				(error) => {
					callbacks.fnError(error)
				}
			)
	}

	meUpdatePicture(id: any, data: any, callbacks: Callbacks): void {
		this.http
			.patch<any>(`${environment.api}/users/${id}`, data)
			.pipe(
				finalize(() => {
					if (callbacks.fnFinalized) callbacks.fnFinalized()
				})
			)
			.subscribe(
				(data) => {
					callbacks.fnSuccess(data)
				},
				(error) => {
					callbacks.fnError(error)
				}
			)
	}
}
