<div class="feedback-header">
	<div class="wrapper-content">
		<div class="">
			<figure class="logo">
				<img
					src="/assets/images/logo.png"
					draggable="false"
					alt="Clínica Rascovski"
					title="Clínica Rascovski"
				/>
			</figure>
			<figure>
				<img
					src="/assets/images/clinica-rascovski.png"
					draggable="false"
					alt="Clínica Rascovski"
					title="Clínica Rascovski"
				/>
			</figure>
		</div>
	</div>
</div>
<div class="feedback-content">
	<div class="card">
		<div class="wrapper-title">
			<h4>
				{{
					step === 1
						? 'Desses temas, quais foram os dois que você teve mais interesse?'
						: 'Desses temas, quais foram os dois que você teve menos interesse?'
				}}
			</h4>
		</div>
		<form action="#" autocomplete="off" (ngSubmit)="onSubmit()">
			<div *ngIf="step === 1">
				<div class="w-100" *ngFor="let item of options; let i = index">
					<div class="form-check form-check-custom form-check-solid">
						<div class="my-4">
							<div class="form-check p-0">
								<input
									class="form-check-input checkbox"
									type="checkbox"
									[disabled]="
										positive.length == 2 &&
										!positive.includes(item.label)
									"
									[id]="item.value + '-positive-' + i"
									name="positive"
									[value]="item.label"
									(change)="
										confirmAnswerFeedback(
											$event,
											'positive'
										)
									"
								/>
								<label
									class="form-check-label"
									[for]="item.value + '-positive-' + i"
								>
									{{ item.label }}
								</label>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div *ngIf="step === 2">
				<div class="w-100" *ngFor="let item of options; let i = index">
					<div class="form-check form-check-custom form-check-solid">
						<div class="my-4">
							<div class="form-check p-0">
								<input
									class="form-check-input checkbox"
									type="checkbox"
									[id]="item.value + '-negative-' + i"
									name="positive"
									[value]="item.label"
									[disabled]="
										negative.length == 2 &&
										!negative.includes(item.label)
									"
									(change)="
										confirmAnswerFeedback(
											$event,
											'negative'
										)
									"
								/>
								<label
									class="form-check-label"
									[for]="item.value + '-negative-' + i"
								>
									{{ item.label }}
								</label>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="wrapper-button d-flex w-100 pb-5 pt-5">
				<button
					*ngIf="step < 2"
					type="button"
					class="btn btn-gold shadow"
					[disabled]="positive.length < 2"
					(click)="nextStep()"
				>
					Continuar
				</button>
				<button
					*ngIf="step == 2"
					type="submit"
					class="btn btn-gold shadow"
					[disabled]="negative.length < 2"
				>
					Enviar
				</button>
			</div>
		</form>
	</div>
</div>
<!--<form action="#" autocomplete="off" (ngSubmit)="onSubmit()" [formGroup]="form">-->
<!--	<div formArrayName="cutScoreOptions">-->
<!--		<div-->
<!--			*ngFor="-->
<!--				let quantity of getCutScoreOptions().controls;-->
<!--				let i = index-->
<!--			"-->
<!--			[formGroupName]="i"-->
<!--			class="mb-6 cut-score"-->
<!--		>-->
<!--			<div class="py-3">-->
<!--				<div class="row">-->
<!--					<div class="col-8" *ngFor="let item of options">-->
<!--						<label for="diagnosis">{{ item.label }}</label>-->
<!--						<input-->
<!--							type="checkbox"-->
<!--							[formControlName]="label"-->
<!--							id="diagnosis"-->
<!--							[setValue]="initialValues[i]?.slug"-->
<!--						/>-->
<!--					</div>-->
<!--				</div>-->
<!--			</div>-->
<!--		</div>-->
<!--	</div>-->

<!--	<div class="wrapper-button d-flex w-100 pb-5">-->
<!--		<button-->
<!--			type="submit"-->
<!--			class="btn btn-gold shadow"-->
<!--			[disabled]="form.invalid"-->
<!--			id="continue-form-journey"-->
<!--		>-->
<!--			Continuar-->
<!--		</button>-->
<!--	</div>-->

<!--	&lt;!&ndash;	<div class="wrapper-button d-flex justify-content-end w-100 pt-10 pb-5">&ndash;&gt;-->
<!--	&lt;!&ndash;		<button&ndash;&gt;-->
<!--	&lt;!&ndash;			type="submit"&ndash;&gt;-->
<!--	&lt;!&ndash;			class="btn btn-primary-ghr me-4"&ndash;&gt;-->
<!--	&lt;!&ndash;			[disabled]="form.invalid"&ndash;&gt;-->
<!--	&lt;!&ndash;		>&ndash;&gt;-->
<!--	&lt;!&ndash;			Consinuar&ndash;&gt;-->
<!--	&lt;!&ndash;		</button>&ndash;&gt;-->
<!--	&lt;!&ndash;	</div>&ndash;&gt;-->
<!--</form>-->
