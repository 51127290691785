import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { FormBuilder, FormGroup, Validators, FormArray } from '@angular/forms'

@Component({
	selector: 'app-form-pillars',
	templateUrl: './form-pillars.component.html',
	styleUrls: ['./form-pillars.component.scss'],
})
export class FormPillarsComponent implements OnInit {
	form: FormGroup
	@Output() emmitEventForm: EventEmitter<any> = new EventEmitter()
	@Input() hasField?: any
	public optionsTypes: any[] = []
	typeInputOnCreat: string = ''
	optionsSelected: any[] = []
	hasOpt: number = 0

	constructor(private fb: FormBuilder) {
		this.form = this.fb.group({
			type: [
				this.hasField ? this.hasField.type : null,
				[Validators.required],
			],
			label: [
				this.hasField ? this.hasField.label : null,
				[Validators.required],
			],
			placeholder: [
				null,
				this.typeInputOnCreat === 'input' ||
				this.typeInputOnCreat === 'select'
					? [Validators.required]
					: [],
			],
			mask: [null, []],
			hint: [null, []],
			itemOptions: this.fb.array([], [Validators.required]),
		})
	}

	ngOnInit(): void {
		// this.optionsTypes = fieldsOptions

		this.getFieldsValues()
	}

	getFieldsValues() {
		if (this.hasField) {
			if (this.hasField.options?.length > 0) {
				for (let i = 0; i < this.hasField.options.length; i++) {
					this.addQuantity()
				}
			}
			this.hasOpt = this.form.value.itemOptions.length
			this.typeInputOnCreat = this.hasField.type
		} else {
			this.addQuantity()
		}
	}

	itemOptions(): FormArray {
		return this.form.get('itemOptions') as FormArray
	}

	newQuantity(): FormGroup {
		return this.fb.group({
			label: '',
			value: '',
			score: '',
		})
	}

	addQuantity() {
		this.itemOptions().push(this.newQuantity())
		this.hasOpt = this.form.value.itemOptions.length
	}

	removeQuantity(i: number) {
		this.itemOptions().removeAt(i)
		this.hasOpt = this.form.value.itemOptions.length
	}

	onSubmit() {
		if (this.form?.valid) {
			let data = {
				...this.form.value,
				options: this.form.value.itemOptions.map((e: any) => {
					return { ...e, value: e.label }
				}),
			}

			this.emmitEventForm.emit(data)
		}
	}

	changedSelectTypes(event: any) {
		this.typeInputOnCreat = event

		if (event === 'input') this.optionsSelected = []
	}
}
