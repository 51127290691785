<div>
    <div class='switch' [formGroup]='form'>
        <label
            [for]="'c-' + id || name"
            [class.disabled]='disabled'
        >
            {{ labelName }}
        </label>
        <input
            type='checkbox'
            [id]="'c-' + id || name"
            [attr.name]='name'
            [attr.disabled]='disabled ? true : null'
            [formControlName]='name'
            [setValue]='inputModel'
            [checked]='inputModel'
            (change)='fnChangeModel($event)'
        />
        <!-- <label [for]="'c-' + id || name"></label> -->
    </div>
</div>
