import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
// import { FormBuilder, FormGroup, Validators } from '@angular/forms'

@Component({
	selector: 'app-feedback',
	templateUrl: './feedback.component.html',
	styleUrls: ['./feedback.component.scss'],
})
export class FeedbackComponent implements OnInit {
	@Output() emmitEventFeedback: EventEmitter<any> = new EventEmitter()
	@Input() formValue: any
	options: any
	// form: FormGroup
	positive: any[] = []
	negative: any[] = []

	step: number = 1

	constructor() {
		// this.form = this.formBuilder.group({
		// 	positive: [[], [Validators.required]],
		// 	negative: [[], [Validators.required]],
		// })
	}

	ngOnInit(): void {
		this.options = this.formValue.map((el: any) => {
			return {
				label: el.title,
				value: el.slug,
			}
		})
	}

	confirmAnswerFeedback(event: any, type: string) {
		const value = event.target.value
		if (event.target.checked) {
			if (type === 'positive' && this.positive.length < 2) {
				this.positive.push(value)
			} else if (type === 'negative' && this.negative.length < 2) {
				this.negative.push(value)
			}
		} else {
			if (type === 'positive') {
				this.positive = this.positive.filter((item: any) => {
					if (item !== value) {
						return item
					}
				})
			} else {
				this.negative = this.negative.filter((item: any) => {
					if (item !== value) {
						return item
					}
				})
			}
		}
	}

	onSubmit() {
		const data = {
			positive: this.positive,
			negative: this.negative,
		}
		this.emmitEventFeedback.emit(data)
	}

	nextStep() {
		this.step = this.step + 1
	}
}
