import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { FormGroup } from '@angular/forms'

@Component({
    selector: 'app-radios',
    templateUrl: './radios.component.html',
    styleUrls: ['./radios.component.scss']
})
export class RadiosComponent implements OnInit {
    @Input() form: FormGroup = new FormGroup(Input())
    @Input() id?: string
    @Input() name: string = ''
    @Input() labelName: string = ''
    @Input() inputModel: any
    @Output() output: EventEmitter<any> = new EventEmitter()

    constructor() {
    }

    ngOnInit(): void {
    }

    onChange(value: string): void {
        this.output.emit(value)
    }
}
