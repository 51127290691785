import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { FormBuilder } from '../../models/form-builder'
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms'
import { GenericValidatorService } from '../../services/validators/generic-validator.service'
import { PillarsFields } from '../../models/pillars'

@Component({
	selector: 'app-form-builder',
	templateUrl: './form-builder.component.html',
	styleUrls: ['./form-builder.component.scss'],
})
export class FormBuilderComponent implements OnInit {
	forms: FormBuilder[] = []
	@Input() formJson: PillarsFields[] = []
	@Output() output: EventEmitter<FormGroup> = new EventEmitter()
	@Output() backPage: EventEmitter<any> = new EventEmitter()
	@Output() nextPage: EventEmitter<any> = new EventEmitter()
	@Output() onSubmitForm: EventEmitter<FormGroup> = new EventEmitter()
	fg: FormGroup = new FormGroup({})

	@Input() answeredByQuestion: any[] = []
	@Output() backQuestion: EventEmitter<any> = new EventEmitter()

	@Input() currentQuestion: number = 1
	hasNextStep: number = 0

	constructor() {}

	ngOnInit(): void {
		this.createForm()
	}

	sendValueInput(event: any) {
		this.onSubmitForm.emit(event)
		if (this.hasNextStep > 0) {
			this.hasNextStep = this.hasNextStep - 1
		}
	}

	createForm() {
		let objectFields = this.formJson
		const formGroup: any = {}
		for (let field of objectFields) {
			if (field.type === 'checkbox') {
				formGroup[field.id] = new FormArray(
					[],
					this.mapValidators(field.validations)
				)
			} else {
				formGroup[field.id] = new FormControl(
					field.value || '',
					this.mapValidators(field.validations)
				)
			}
		}

		this.fg = new FormGroup(formGroup)

		const form: FormBuilder = {
			id: new Date().getUTCMilliseconds().toString(),
			formGroup: this.fg,
			metaData: objectFields,
			transactionalData: [],
		}
		this.fg.valueChanges.subscribe((values) => {
			this.output.emit(this.fg)
		})
		this.forms.push(form)
		return form
	}

	private mapValidators(validators: any) {
		const formValidators = []

		if (validators) {
			for (const validation of Object.keys(validators)) {
				if (validation === 'required') {
					formValidators.push(Validators.required)
				} else if (validation === 'minLength') {
					formValidators.push(
						Validators.minLength(validators[validation])
					)
				} else if (validation === 'maxLength') {
					formValidators.push(
						Validators.maxLength(validators[validation])
					)
				} else if (validation === 'isValidCpf') {
					formValidators.push(GenericValidatorService.isValidCpf)
				}
			}
		}

		return formValidators
	}

	backStepQuestion(): void {
		this.currentQuestion = this.currentQuestion - 1
		this.hasNextStep = this.hasNextStep + 1
		this.backPage.emit(this.currentQuestion)
	}

	nextStepQuestion(): void {
		this.currentQuestion = this.currentQuestion + 1
		this.nextPage.emit(this.currentQuestion)
		if (this.hasNextStep > 0) {
			this.hasNextStep = this.hasNextStep - 1
		}
	}
	onSubmit(form: FormGroup) {}
}
