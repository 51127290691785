<div class="modal-body pt-8">
    <div class="form" *ngIf="!isSend">
        <h3 class="mb-5">Quer convidar um amigo?</h3>
        <p class="mb-10">Informe o e-mail para que a gente possa enviar uma mensagem pra ele participar com você!</p>

        <form
            action="#"
            autocomplete="off"
            (ngSubmit)="onSubmit()"
            [formGroup]="form"
        >
            <app-input
                id="email"
                name="email"
                typeInput="text"
                labelName="E-mail"
                placeholder="E-mail"
                [form]="form"
            ></app-input>

            <button
                type="submit"
                class="btn mt-10 mb-5"
                [disabled]="form.invalid"
            >
                Convidar amigo
            </button>
        </form>
    </div>
    <div class="feedback" *ngIf="isSend">
        <figure>
            <img src="/assets/images/icon-send.png" alt="enviado">
        </figure>
        <h3 class="mb-5">O e-mail foi enviado! </h3>
        <p class="mb-10">Ficamos felizes com a sua participação.</p>
        <button
            type="button"
            class="btn mt-10 mb-5"
            (click)="close()"
        >
            Sair
        </button>
    </div>
</div>
