import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'

@Component({
	selector: 'app-modal-custom',
	templateUrl: './modal-custom.component.html',
	styleUrls: ['./modal-custom.component.scss'],
})
export class ModalCustomComponent implements OnInit {
	@Output() close: EventEmitter<any> = new EventEmitter()
	@Input() openModal: boolean = false

	constructor() {}

	ngOnInit(): void {}

	closeModal() {
		this.openModal = false
		this.close.emit('close')
	}
}
