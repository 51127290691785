import { HttpClient, HttpHeaders } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Login } from './login'
import { environment } from 'src/environments/environment'
import { finalize, tap } from 'rxjs/operators'
import { Router } from '@angular/router'
import { HelpersService } from '../helpers/helpers.service'
import { AuthResult } from './auth-result'
import { ToastrService } from 'ngx-toastr'
import * as moment from 'moment'
import { Callbacks } from '../../models/callbacks'
import { of } from 'rxjs'
import { AppState } from '../../state/app-state'
import { Store } from '@ngrx/store'
import { AddUser, ClearUser } from '../../state/user/user.action'
import { HiddenLoading } from '../../state/loading/loading.action'
import { MeService } from '../user/me.service'
// import { Observable } from 'rxjs'
// import { User } from 'src/app/models/user'
// import { Store } from '@ngrx/store'

@Injectable({
	providedIn: 'root',
})
export class AuthService {
	isLogin = false
	needUpdatePassword: boolean = true
	// user?: User

	constructor(
		private http: HttpClient,
		private router: Router,
		private helpers: HelpersService,
		private toast: ToastrService,
		private store: Store<AppState>,
		private meService: MeService
	) {}

	loginHasSessionUser(body: Login) {
		return this.http
			.post<Login>(`${environment.api}/auth/login/`, body.data)
			.pipe(
				finalize(() => {
					if (body.fnFinalized) body.fnFinalized
				})
			)
			.subscribe((data) => {
				body.fnSuccess(data)
			})
	}

	loginUser(body: Login) {
		return this.http
			.post<any>(`${environment.api}/auth/login/`, body.data)
			.pipe(
				finalize(() => {
					if (body.fnFinalized) body.fnFinalized()
				})
			)
			.subscribe((data) => {
				const resultData: AuthResult = data.data
				this.setSession(resultData)
				body.fnSuccess()
			}, body.fnError)
	}

	private setSession({ access, refresh }: AuthResult) {
		const { exp } = this.helpers.decodePayloadJWT(access)
		const { users } = this.helpers.decodePayloadJWT(access)

		const expiresAt = moment().add(exp, 'second')
		this.isLogin = true
		localStorage.setItem('token', access)
		localStorage.setItem('refresh', refresh)
		localStorage.setItem('user', users)
		localStorage.setItem('expires_at', JSON.stringify(expiresAt.valueOf()))
		this.meService.me(users)
	}

	setUserState(): void {
		const token = localStorage.getItem('token') || ''
		if (token) {
			let { user } = this.helpers.decodePayloadJWT(token)
			this.needUpdatePassword = user.need_update_password
		}
	}

	logout() {
		return this.http.post(`${environment.api}/auth/logout`, {})
	}

	logOutSectionLocal() {
		this.isLogin = false
		localStorage.removeItem('token')
		localStorage.removeItem('refresh')
		localStorage.removeItem('expires_at')
		localStorage.removeItem('user')
		return of({ success: this.isLogin })
	}

	redirectAuth(): void {
		let redirectUrl = '/companies'
		this.router.navigate([redirectUrl])
	}

	getExpiration() {
		const expiration = localStorage.getItem('expires_at')
		const expiresAt = expiration ? JSON.parse(expiration) : ''
		return expiresAt ? moment(expiresAt) : ''
	}

	isLoggedIn() {
		this.isLogin = moment().isBefore(this.getExpiration())
		return this.isLogin
	}

	getToken(): string {
		return localStorage.getItem('token') || ''
	}

	accessToken() {
		const token = this.getToken()
		return this.http
			.post<any>(`${environment.api}/auth/verify`, {
				token: token,
			})
			.pipe(
				tap((data) => {
					const resultData: AuthResult = data.data
					this.setSession(resultData)
				})
			)
	}

	getRefreshToken(): string {
		return localStorage.getItem('refresh') || ''
	}

	getUserLocale(): any {
		const token = localStorage.getItem('token') || ''
		const { users } = this.helpers.decodePayloadJWT(token)
		return users
	}

	refreshToken() {
		const token = this.getRefreshToken()
		return this.http
			.post<any>(`${environment.api}/auth/refresh/`, {
				refresh: token,
			})
			.pipe(
				tap((data) => {
					const resultData: AuthResult = data.data
					this.setSession(resultData)
				})
			)
	}

	defaultPassVerify(): any {
		const passDefault = this.needUpdatePassword
		if (this.needUpdatePassword) {
			return passDefault
		}
	}

	redirectDefaultPassAuth(): void {
		window.location.href = '/alterar-senha'
	}

	recoverPassword(
		dataContact: any,
		uidb64: any,
		token: any,
		callback: Callbacks
	) {
		this.http
			.patch<any>(
				`${environment.api}/auth/recover-password/${uidb64}/${token}/`,
				{ ...dataContact }
			)
			.pipe(
				finalize(() => {
					if (callback?.fnFinalized) callback.fnFinalized()
				})
			)
			.subscribe(
				({ data }) => {
					callback?.fnSuccess(data)
				},
				(err) => {
					callback?.fnError(err)
				}
			)
	}

	// change password function
	changePassword(id: any, data: any, callback?: Callbacks) {
		this.http
			.put<any>(`${environment.api}/auth/change_password/`, {
				...data,
			})
			.pipe(
				finalize(() => {
					if (callback?.fnFinalized) callback.fnFinalized()
				})
			)
			.subscribe(
				() => {
					callback?.fnSuccess()
				},
				(err) => {
					console.warn(err)
					callback?.fnError(err)
				}
			)
	}

	forgotPassword(dataContact: any, callback?: Callbacks) {
		const data = {
			email: dataContact.email.toLowerCase(),
			...dataContact,
		}

		this.http
			.post(`${environment.api}/auth/request-reset-email/`, {
				...data,
			})
			.pipe(
				finalize(() => {
					if (callback?.fnFinalized) callback.fnFinalized()
				})
			)
			.subscribe(
				(data) => {
					callback?.fnSuccess(data)
				},
				(err) => {
					console.warn(err)
					callback?.fnError(err)
				}
			)
	}
}

// companies/companies_list
