import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {GenericValidatorService} from "../../../services/validators/generic-validator.service";
import {JourneyService} from "../../../services/journey/journey.service";
import {HiddenLoading, ShowLoading} from "../../../state/loading/loading.action";
import {Store} from "@ngrx/store";
import {ModalStatusComponent} from "../modal-status/modal-status.component";

@Component({
    selector: 'app-modal-email',
    templateUrl: './modal-email.component.html',
    styleUrls: ['./modal-email.component.scss']
})
export class ModalEmailComponent implements OnInit {
    @Input() slug: string = ''
    @Input() session: string = ''
    @Input() onError?: Function

    form: FormGroup
    isSend: boolean = false

    constructor(
        public activeModal: NgbActiveModal,
        private formBuilder: FormBuilder,
        private journeyService: JourneyService,
        private store: Store
    ) {
        this.form = this.formBuilder.group({
            email: [
                null,
                [Validators.required, Validators.email],
            ],
        })
    }

    ngOnInit(): void {}

    close() {
        this.activeModal.close()
    }
    onSubmit() {
        const self = this
        if (self.form?.valid) {
            self.store.dispatch(ShowLoading())
            const data = this.form.value
            data.session = this.session
            self.journeyService.sendInviteEmail(self.slug, data, {
                fnSuccess(res) {
                    self.isSend = true
                },
                fnFinalized() {
                    self.store.dispatch(HiddenLoading())
                },
                fnError() {
                    if (self.onError) self.onError()
                    self.close()
                },
            })
        }
    }
}
