import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'

@Component({
	selector: 'app-board',
	templateUrl: './board.component.html',
	styleUrls: ['./board.component.scss'],
})
export class BoardComponent implements OnInit {
	@Output() emmitEventClick: EventEmitter<any> = new EventEmitter()
	@Input() title?: string = ''
	@Input() description?: string = ''
	@Input() btnTitle?: string = ''

	constructor() {}

	ngOnInit(): void {}

	setQuestionnaire() {
		this.emmitEventClick.emit()
	}
}
