<div class="wrapper-form">
	<h3 class="form-title">Add new Pillar</h3>
	<form
		action="#"
		autocomplete="off"
		(ngSubmit)="onSubmit()"
		[formGroup]="form"
	>
		<div class="mb-12">
			<app-select
				id="type"
				name="type"
				[options]="optionsTypes"
				labelName="Field Type"
				placeholder="Select field type"
				[inputModel]="hasField?.type"
				[form]="form"
				(output)="changedSelectTypes($event)"
			></app-select>
		</div>
		<div class="mb-12">
			<app-input
				id="label"
				name="label"
				typeInput="text"
				placeholder="What was the event you went to?"
				labelName="Label name field"
				[inputModel]="hasField?.label"
				[form]="form"
			></app-input>
		</div>

		<div
			class="mb-12"
			*ngIf="typeInputOnCreat === 'text' || typeInputOnCreat === 'select'"
		>
			<app-input
				id="placeholder"
				name="placeholder"
				typeInput="text"
				placeholder="Placeholder field"
				labelName="Placeholder field"
				[inputModel]="hasField?.placeholder"
				[form]="form"
			></app-input>
		</div>

		<div
			*ngIf="
				typeInputOnCreat === 'select' ||
				typeInputOnCreat === 'checkbox' ||
				typeInputOnCreat === 'radio'
			"
		>
			<div formArrayName="itemOptions">
				<div
					*ngFor="
						let quantity of itemOptions().controls;
						let i = index
					"
					[formGroupName]="i"
					class="mb-12"
				>
					<div class="list-options" *ngIf="this.hasField?.options?.length > 0">
						<div class="field">
							<input
								type="text"
								formControlName="label"
								class="form-control"
								placeholder="option name"
								[setValue]="hasField?.options[i]?.label || ''"
							/>
						</div>
						<div class="field">
							<input
								type="number"
								formControlName="score"
								class="form-control"
								placeholder="Score"
								[setValue]="hasField?.options[i]?.score || ''"
							/>
						</div>
						<button
							(click)="removeQuantity(i)"
							[disabled]="hasOpt <= 1"
						>
							<img
								src="/assets/images/trash.svg"
								alt="Remover"
								title="Remover"
							/>
						</button>
					</div>
					<div class="list-options" *ngIf="this.hasField?.options?.length <= 0">
						<div class="field">
							<input
								type="text"
								formControlName="label"
								class="form-control"
								placeholder="option name"
								[setValue]="''"
							/>
						</div>
						<div class="field">
							<input
								type="number"
								formControlName="score"
								class="form-control"
								placeholder="Score"
								[setValue]="''"
							/>
						</div>
						<button
							(click)="removeQuantity(i)"
							[disabled]="hasOpt <= 1"
						>
							<img
								src="/assets/images/trash.svg"
								alt="Remover"
								title="Remover"
							/>
						</button>
					</div>
				</div>
			</div>
			<div class="wrapper-button px-12">
				<button type="button" (click)="addQuantity()" class="btn">
					Adicionar opção
				</button>
			</div>
		</div>

		<div class="wrapper-button d-flex justify-content-end w-100 pt-10 pb-5">
			<button
				type="submit"
				class="btn btn-primary-ghr me-4"
				[disabled]="form.invalid"
			>
				Salvar
			</button>
		</div>
	</form>
</div>
