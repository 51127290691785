import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { FormGroup, Validators, FormBuilder } from '@angular/forms'
import { GenericValidatorService } from '../../../services/validators/generic-validator.service'
import { HelpersService } from '../../../services/helpers/helpers.service'

@Component({
    selector: 'app-personal-data-form',
    templateUrl: './personal-data-form.component.html',
    styleUrls: ['./personal-data-form.component.scss']
})
export class PersonalDataFormComponent implements OnInit {
    @Input() documentValue: any = ''
    @Input() initialValue: any = null
    @Input() hasPersonalId: boolean = false
    @Output() emmitEventForm: EventEmitter<any> = new EventEmitter()
    form: FormGroup

    constructor(private formBuilder: FormBuilder, private helpers: HelpersService) {
        this.form = this.formBuilder.group({
            name: [
                null,
                [Validators.required]
            ],
            birth_date: [
                null,
                [Validators.required, GenericValidatorService.validateDate, GenericValidatorService.validateMore18]
            ],
            email: [
                null,
                [Validators.required, Validators.email]
            ],
            cpf: [
                null,
                [Validators.required, GenericValidatorService.isValidCpf]
            ],
            phone: [
                null,
                [Validators.required, GenericValidatorService.validatePhone]
            ],
            term: [null, [Validators.requiredTrue]]
        })
    }

    ngOnInit(): void {
    }

    onSubmit() {
        let { birth_date } = this.form.value
        birth_date = this.helpers.transformDateUS(
            this.helpers.addMaskDate(birth_date)
        )
        const data = { ...this.form.value, birth_date }
        this.emmitEventForm.emit(data)
        this.form.reset()
    }
}
