<div class="controls-form-builder">
    <ng-container [ngSwitch]="prop.type">
        <ng-container *ngSwitchCase="'radio'">
            <h5 class="form-label">
                {{ prop.label }}
            </h5>
            <div [class.inline]="prop.is_default_field">
                <div *ngFor="let option of prop.options; let i = index">
                    <label>
                        <app-radios
                            [form]="form"
                            [id]="name + '-' + i"
                            [name]="name"
                            [inputModel]="option.value"
                            (output)="onChangeRadius($event, prop.id)"
                        >
                            {{ option.value }}
                        </app-radios>
                        <app-control-error
                            [form]="form"
                            [name]="name"
                            [labelName]="prop.label"
                        ></app-control-error>
                    </label>
                </div>
            </div>
        </ng-container>

        <!--	<ng-container *ngSwitchCase="'text'">-->
        <!--		<app-input-->
        <!--			[id]="name"-->
        <!--			[typeInput]="prop.type"-->
        <!--			[name]="name"-->
        <!--			[labelName]="prop.label"-->
        <!--			[placeholder]="prop.placeholder"-->
        <!--			[form]="form"-->
        <!--			[inputMask]="prop.mask || null"-->
        <!--			[inputModel]="prop.value"-->
        <!--			(emmitEvent)="onChange($event)"-->
        <!--		></app-input>-->
        <!--	</ng-container>-->

        <!--	<ng-container *ngSwitchCase="'select'">-->
        <!--		<app-select-->
        <!--			[id]="name"-->
        <!--			[name]="name"-->
        <!--			[options]="prop.options"-->
        <!--			[labelName]="prop.label"-->
        <!--			[placeholder]="prop.placeholder"-->
        <!--			[form]="form"-->
        <!--			[inputModel]="prop.value"-->
        <!--			(output)="onChange($event)"-->
        <!--		></app-select>-->
        <!--	</ng-container>-->

        <!--	<ng-container *ngSwitchCase="'checkbox'">-->
        <!--		<label>-->
        <!--			<h5>{{ prop.label }}</h5>-->
        <!--		</label>-->

        <!--		<div *ngFor="let option of prop.options; let i = index">-->
        <!--			<label>-->
        <!--				<app-checkbox-form-builder-->
        <!--					[form]="form"-->
        <!--					[id]="name + '-' + i"-->
        <!--					[name]="name"-->
        <!--					[value]="option.value"-->
        <!--					[changeModel]="onCheckChange"-->
        <!--					(output)="onChange($event)"-->
        <!--				>-->
        <!--					{{ option.value }}-->
        <!--				</app-checkbox-form-builder>-->

        <!--				<app-control-error-->
        <!--					[form]="form"-->
        <!--					[name]="name"-->
        <!--					[labelName]="prop.label"-->
        <!--				></app-control-error>-->
        <!--			</label>-->
        <!--		</div>-->
        <!--	</ng-container>-->
    </ng-container>
</div>
