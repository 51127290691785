<div class="header">
	<!-- <div class='container'>
            <figure class='logo'>
                <img src='/assets/images/logo.png' alt='Cérebro em ação'>
            </figure>
        </div> -->
	<div class="container">
		<div class="logo">
			<img
				src="assets/icons/atma-soma-logo.svg"
				alt="Clínica Atma Soma logo"
			/>
		</div>
	</div>
</div>

<div class="quiz">
	<!-- <div class='welcome' *ngIf="step === 'welcome'">
        <div class='container'>
            <figure class='logo'>
                <img
                    src='/assets/images/logo-white.png'
                    alt='Cérebro em ação'
                    title='Cérebro em ação'
                />
            </figure>
            <h2>
                <strong>Olá!</strong><br />
                Esta é a Roda do Cérebro, idealizada para ajudá-lo(a) a fazer uma auto avaliação dos Fatores de Riscos
                preveníveis de demências, em especial a Doença de Alzheimer
            </h2>
            <button
                class='btn btn-white ga-btn'
                [attr.data-gaLabel]="'Continuar'"
                (click)="changeStep('personalData')"
            >
                Continuar
            </button>
        </div>
    </div> -->
	<div class="start-quiz question" *ngIf="step === 'personalData'">
		<!-- <h3 class="mb-5">Olá!</h3>
		<p class="mb-5">
			Todas as informações solicitadas para o cadastramento na plataforma
			serão anonimizadas e nunca utilizados individualmente, conforme
			termos e políticas de privacidade abaixo.
		</p>
		<p class="mb-10">
			Se você já preencheu o seu cadastro antes basta iniciar o
			questionário, caso contrário complete as suas informações para
			continuar.
		</p> -->
		<h3 class="mb-8">Informações pessoais</h3>
		<app-personal-data-form
			*ngIf="!loadingPersonalData && _reload"
			(emmitEventForm)="savePersonalData($event)"
		></app-personal-data-form>
	</div>
	<div class="my-quiz question" *ngIf="step === 'listPillar'">
		<h3 class="mb-5">Meus questionários</h3>
		<p class="mb-5">
			Para responder os questionários você precisa selecionar uma das
			opções abaixo.
		</p>
		<p class="mb-10">
			<strong>Leia atentamente as opções antes de responder</strong>
		</p>
		<ul class="list-quiz">
			<li
				*ngFor="let pillar of listQuiz"
				[ngClass]="{ checked: checkPillarsUserStorage(pillar.id) }"
			>
				<p (click)="choosePillar(pillar.slug)">{{ pillar.title }}</p>
				<button
					type="button"
					[attr.data-gaLabel]="'Iniciar Pillar - ' + pillar.title"
					*ngIf="!checkPillarsUserStorage(pillar.id)"
					(click)="choosePillar(pillar.slug)"
					class="customButton-small"
				>
					Iniciar
				</button>
			</li>
		</ul>
	</div>
	<div class="wrapper-response p-0" *ngIf="step === 'questions'">
		<!-- <div class="header-pillar">
			<figure>
				<img
					src="/assets/images/header-questions.jpg"
					alt="Cérebro em ação"
				/>
			</figure>
		</div> -->
		<div class="m-0" *ngIf="currentPillar && _reload">
			<div class="intro-pillar question" *ngIf="!answerQuestionnaire">
				<h3 class="text-center mb-7">{{ currentPillar.title }}</h3>
				<p>{{ currentPillar.description }}</p>
				<div class="buttons">
					<button
						type="button"
						class="btn btn-transparent ga-btn"
						[attr.data-gaLabel]="'Voltar formulário'"
						(click)="changeStep('listPillar')"
					>
						Voltar
					</button>
					<button
						type="button"
						class="customButton"
						[attr.data-gaLabel]="'Continuar formulário'"
						(click)="setQuestionnaire()"
					>
						Continuar
					</button>
				</div>
			</div>
			<div class="question aside-form" *ngIf="answerQuestionnaire">
				<div class="title-group">
					<h3>
						{{ currentPillar.title }}
					</h3>
					<p>{{ currentPillar.field.length }} questões</p>
					<div class="wrapper-tabs">
						<span
							*ngFor="
								let pillar of currentPillar.field;
								let i = index
							"
							[class.active]="currentQuestion >= i + 1"
						></span>
					</div>
				</div>

				<app-form-builder
					(onSubmitForm)="onSubmitPillar($event)"
					(backPage)="backPage($event)"
					(nextPage)="nextPage($event)"
					[currentQuestion]="currentStep"
					[formJson]="currentPillar.field"
				>
				</app-form-builder>
			</div>
		</div>
	</div>
	<div class="results question" *ngIf="step === 'results'">
		<h3 class="text-center mb-3">Obrigado por participar!</h3>
		<!-- <p class="text-center mb-10">Esse é o seu resultado</p> -->
		<!-- <div *ngIf="results.hasOwnProperty('good')" class="evaluation success">
			<div class="evaluation-header">Avaliação Boa</div>
			<div class="evaluation-body">
				<p>Parabéns! Continue se cuidando, seu Cérebro agradece!</p>
				<ul>
					<li *ngFor="let result of convertObjToArray(results.good)">
						{{ result.title }}
					</li>
				</ul>
			</div>
		</div>
		<div
			*ngIf="results.hasOwnProperty('satisfactory')"
			class="evaluation warning"
		>
			<div class="evaluation-header">Avaliação Satisfatória</div>
			<div class="evaluation-body">
				<p>
					Cuidado! Seu cérebro está em zona de risco. Dê mais atenção
					a esses fatores.
				</p>
				<ul>
					<li
						*ngFor="
							let result of convertObjToArray(
								results.satisfactory
							)
						"
					>
						{{ result.title }}
					</li>
				</ul>
			</div>
		</div>
		<div
			*ngIf="results.hasOwnProperty('terrible')"
			class="evaluation danger"
		>
			<div class="evaluation-header">Avaliação Ruim</div>
			<div class="evaluation-body">
				<p>
					Esse não é um bom resultado. Vamos ver o que é possível
					melhorar?!<br />
					Mudar os hábitos, em alguns casos, pode fazer uma grande
					diferença, mas se for preciso, busque ajuda profissional.<br />
					E se você já está tratando os fatores de risco, continue
					focado no tratamento!
				</p>
				<ul>
					<li
						*ngFor="
							let result of convertObjToArray(results.terrible)
						"
					>
						{{ result.title }}
					</li>
				</ul>
			</div>
		</div>
		<p>Essa pesquisa não é válida como diagnóstico médico.</p>
		<p>
			Nosso intuito é oferecer um parâmetro de autoavaliação e
			conscientizar como o estilo de vida da população brasileira tem
			contribuído ou não na prevenção do Alzheimer.
		</p>
		<p class="mb-10">A hora de cuidar do seu cérebro é agora!</p> -->
		<!-- <h4 class="text-center mb-3">Convidar amigo</h4>
		<div
			class="share d-flex align-items-center justify-content-center gap-5 mb-5"
		>
			<figure (click)="shareWhatsapp()">
				<img src="/assets/images/icon-whatsapp.png" alt="whatsapp" />
			</figure>
			<figure (click)="openModalInviteEmail()">
				<img src="/assets/images/icon-email.png" alt="email" />
			</figure>
		</div> -->
		<!--        <button class="btn mt-15 mb-4" (click)="restartFlow()">-->
		<!--            Responder novamente-->
		<!--        </button>-->
	</div>
	<div class="already-answered" *ngIf="step === 'alreadyAnswered'">
		<!-- <figure class="logo">
			<img
				src="/assets/images/header-answared.jpg"
				alt="Cérebro em ação"
			/>
		</figure> -->
		<div class="wrapper-content">
			<div class="container">
				<figure>
					<img src="/assets/images/icon-alert.png" alt="!" />
				</figure>
				<h3 class="text-center mb-3">
					Você já preencheu esse formulário
				</h3>
				<p class="text-center mb-10">
					Esse pilar já foi respondido.<br />
					Selecione outro para continuar respondendo
				</p>
				<button
					class="btn ga-btn"
					[attr.data-gaLabel]="'Escolher questionário'"
					(click)="changeStep('listPillar')"
				>
					Escolher questionário
				</button>
			</div>
		</div>
	</div>
	<div class="finish" *ngIf="step === 'finished'">
		<app-board>
			<div
				content
				class="h-100 d-flex align-items-center justify-content-center"
			>
				<figure>
					<img
						src="./assets/images/success-quiz.gif"
						alt="sucesso responder o questionário"
						title="sucesso responder o questionário"
					/>
				</figure>
			</div>
		</app-board>
	</div>
</div>
<div class="footer text-white">
	<div class="container d-flex align-items-center footerContent">
		<div class="responsable">
			<img
				class="footerLogo"
				src="/assets/icons/atma-soma-logo.svg"
				alt="Clínica Atma Soma"
			/>
			<p><strong>Responsável Técnico:</strong></p>
			<p>Dra. Alessandra Rascovski - CRM: 80126/SP</p>
		</div>
		<div class="address">
			<p><strong>Endereço</strong></p>
			<p>
				Alameda Gabriel Monteiro da Silva, 2792, Jardim América - São
				Paulo - SP - 01442-002.
			</p>
			<p><strong>Atendimento</strong></p>
			<p>De segunda à sexta, das 8h às 20h.</p>
		</div>
		<div class="contact">
			<a
				class="whatsapp d-block text-white fw-bold fs-2"
				href="https://wa.me/551155451818"
				target="_blank"
				>(11) 5545-1818</a
			>
			<a
				class="email d-block text-white fs-3"
				href="mailto:contato@atmasoma.com"
				>contato@atmasoma.com</a
			>
			<a
				class="instagram d-block text-white"
				href="https://www.instagram.com/atma.soma"
				>Instagram</a
			>
		</div>
	</div>
	<div class="container privacyPolicy">
		<a
			href="https://atmasoma.com/politica-de-privacidade/"
			download
			target="_blank"
			>Política de Privacidade</a
		>
	</div>
</div>
