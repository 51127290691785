import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { Callbacks } from '../../models/callbacks'
import { environment } from '../../../environments/environment'
import { finalize } from 'rxjs/operators'

@Injectable({
    providedIn: 'root',
})
export class JourneyService {
    constructor(private http: HttpClient) {}

    private setParams(personalId: string): any {
        let params: any = {}

        if (personalId) {
            params.personal_id = personalId
        }

        return params
    }

    getQuiz(slug: string, callbacks: Callbacks): void {
        this.http
            .get<any>(`${environment.api}/quiz/${slug}/`)
            .pipe(
                finalize(() => {
                    if (callbacks.fnFinalized) callbacks.fnFinalized()
                })
            )
            .subscribe({
                next: (data) => {
                    callbacks.fnSuccess(data)
                },
                error: (error) => {
                    callbacks.fnError(error)
                },
            })
    }
    saveDataUser(slug: string, data: any, callbacks: Callbacks): void {
        this.http
            .post<any>(`${environment.api}/quiz/${slug}/identification/`, data)
            .pipe(
                finalize(() => {
                    if (callbacks.fnFinalized) callbacks.fnFinalized()
                })
            )
            .subscribe({
                next: (data) => {
                    callbacks.fnSuccess(data)
                },
                error: (error) => {
                    callbacks.fnError(error)
                },
            })
    }
    sendInviteEmail(slug: string, data: any, callbacks: Callbacks): void {
        this.http
            .post<any>(`${environment.api}/${slug}/send_email/`, data)
            .pipe(
                finalize(() => {
                    if (callbacks.fnFinalized) callbacks.fnFinalized()
                })
            )
            .subscribe({
                next: (data) => {
                    callbacks.fnSuccess(data)
                },
                error: (error) => {
                    callbacks.fnError(error)
                },
            })
    }
    savePillarJourney(
        slugQuiz: string,
        slugPillar: string,
        data: any,
        callbacks: Callbacks
    ): void {
        this.http
            .post<any>(
                `${environment.api}/response/${slugQuiz}/${slugPillar}/`,
                data
            )
            .pipe(
                finalize(() => {
                    if (callbacks.fnFinalized) callbacks.fnFinalized()
                })
            )
            .subscribe({
                next: (data) => {
                    callbacks.fnSuccess(data)
                },
                error: (error) => {
                    callbacks.fnError(error)
                },
            })
    }
}
