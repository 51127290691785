<form
	class="personal-data"
	action="#"
	autocomplete="off"
	(ngSubmit)="onSubmit()"
	[formGroup]="form"
>
	<div>
		<app-input
			id="name"
			name="name"
			typeInput="text"
			labelName="Nome"
			placeholder="Nome"
			[form]="form"
		></app-input>
		<app-input
			id="email"
			name="email"
			typeInput="text"
			labelName="E-mail"
			placeholder="E-mail"
			[form]="form"
		></app-input>
		<div class="row">
			<div class="col-md-6">
				<app-input
					id="cpf"
					name="cpf"
					typeInput="text"
					labelName="CPF"
					placeholder="CPF"
					inputMask="000.000.000-00"
					[form]="form"
				></app-input>
			</div>
			<div class="col-md-6">
				<app-input
					id="birth_date"
					name="birth_date"
					typeInput="text"
					labelName="Data de nascimento"
					[form]="form"
					inputMask="00/00/0000"
					placeholder="00/00/0000"
				></app-input>
			</div>
			<div class="col-md-6">
				<app-input
					id="phone"
					name="phone"
					typeInput="text"
					labelName="Telefone"
					placeholder="Telefone"
					inputMask="(00) 0000-0000||(00) 0 0000-0000"
					[form]="form"
				></app-input>
			</div>
		</div>
		<app-checkbox
			id="term"
			name="term"
			class="d-block mt-3 mb-3"
			labelName="Para continuar você precisa aceitar os nossos "
			[form]="form"
		>
			<a
				href="https://atmasoma.com/politica-de-privacidade/"
				download
				target="_blank"
				class="link text-decoration-underline"
				>termos e políticas de privacidade</a
			>.
		</app-checkbox>
	</div>
	<div class="wrapper-button d-flex w-100">
		<button
			type="submit"
			class="customButton"
			[disabled]="form.invalid || hasPersonalId"
			id="continue-form-journey"
		>
			Iniciar questionário
		</button>
	</div>
</form>
