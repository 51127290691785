import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { FormBuilder, FormGroup, Validators, FormArray } from '@angular/forms'
import { Fields } from 'src/app/models/fields'

@Component({
	selector: 'app-form-fields',
	templateUrl: './form-fields.component.html',
	styleUrls: ['./form-fields.component.scss'],
})
export class FormFieldsComponent implements OnInit {
	form: FormGroup
	@Output() emmitEventForm: EventEmitter<any> = new EventEmitter()
	@Input() hasField?: any
	public optionsTypes: Fields[] = []
	selectScore: Fields[] = []
	typeInputOnCreat: string = ''
	optionsSelected: any[] = []
	hasOpt: number = 0

	constructor(private fb: FormBuilder) {
		this.form = this.fb.group({
			type: [
				this.hasField ? this.hasField.type : null,
				[Validators.required],
			],
			label: [
				this.hasField ? this.hasField.label : null,
				[Validators.required],
			],
			placeholder: [
				null,
				this.typeInputOnCreat === 'input' ||
				this.typeInputOnCreat === 'select'
					? [Validators.required]
					: [],
			],
			mask: [null, []],
			hint: [null, []],
			itemOptions: this.fb.array([], []),
		})
	}

	ngOnInit(): void {
		// this.optionsTypes = fieldsOptions
		// this.selectScore = fieldsScore
		// this.getFieldsValues()
	}

	getFieldsValues() {
		if (this.hasField) {
			if (this.hasField.options) {
				if (
					this.hasField.options?.length > 0 &&
					this.hasField.options?.length >
						this.form.value.itemOptions.length
				) {
					for (
						let i = this.form.value.itemOptions.length;
						i < this.hasField.options.length;
						i++
					) {
						this.addQuantity()
					}
				}
			} else {
				this.hasField.options = {
					label: '',
					value: '',
					score: '',
				}
				this.addQuantity()
			}
			this.hasOpt = this.form.value.itemOptions.length
			this.typeInputOnCreat = this.hasField.type
		} else {
			this.addQuantity()
		}
	}

	itemOptions(): FormArray {
		return this.form.get('itemOptions') as FormArray
	}

	newQuantity(): FormGroup {
		return this.fb.group({
			label: '',
			value: '',
			score: '',
		})
	}

	addQuantity() {
		this.itemOptions().push(this.newQuantity())
		this.hasOpt = this.form.value.itemOptions.length
	}

	removeQuantity(i: number) {
		if (this.hasField && this.hasField.options.length > 0) {
			this.hasField.options.splice(i, 1)
		}
		this.itemOptions().removeAt(i)
		this.hasOpt = this.form.value.itemOptions.length
	}

	onSubmit() {
		if (this.form?.valid) {
			let data = {
				...this.form.value,
				options: this.form.value.itemOptions.map((e: any) => {
					return { ...e, value: e.label }
				}),
			}

			this.emmitEventForm.emit(data)
		}
	}

	changedSelectTypes(event: any) {
		this.typeInputOnCreat = event

		if (event === 'input') this.optionsSelected = []
	}
}
