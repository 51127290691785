import { NgModule } from '@angular/core'
import { DndModule } from 'ngx-drag-drop'
import { NgWizardModule, NgWizardConfig, THEME } from 'ng-wizard'
import { BrowserModule, Meta } from '@angular/platform-browser'
import { CommonModule } from '@angular/common'
import { HttpClientModule } from '@angular/common/http'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { ToastrModule } from 'ngx-toastr'
import { NgxMaskModule } from 'ngx-mask'
import { StoreModule } from '@ngrx/store'
import { NgbModule } from '@ng-bootstrap/ng-bootstrap'

import { stateReducers } from './state/reducers'

import { SetValueDirective } from './directives/set-value.directive'
import { SetFormControlErrorDirective } from './directives/set-form-control-error.directive'

// import { HTTP_INTERCEPTORS } from '@angular/common/http'
// import { AuthInterceptorService } from './services/auth/auth-interceptor'
import { AppRoutingModule } from './app-routing.module'
import { AppComponent } from './app.component'
import { LoadingGlobalComponent } from './components/loading/loading-global/loading-global.component'
import { LoadingLocalComponent } from './components/loading/loading-local/loading-local.component'
import { LoadingProgressComponent } from './components/loading/loading-progress/loading-progress.component'
import { InputComponent } from './components/form/input/input.component'
import { CheckboxCustomComponent } from './components/form/checkbox-custom/checkbox-custom.component'
import { CheckboxComponent } from './components/form/checkbox/checkbox.component'
import { SelectComponent } from './components/form/select/select.component'
import { RadiosComponent } from './components/form/radios/radios.component'
import { ModalDefaultComponent } from './components/modals/modal-default/modal-default.component'
import { VersionComponent } from './components/version/version.component'
import { NotFoundComponent } from './pages/not-found/not-found.component'
import { LazyLoadImageModule } from 'ng-lazyload-image'
import { ControlErrorComponent } from './components/form/control-error/control-error.component'
import { FormBuilderComponent } from './components/form-builder/form-builder.component'
import { ControlsFormBuilderComponent } from './components/form-builder/controls-form-builder/controls-form-builder.component'
import { CheckboxFormBuilderComponent } from './components/form/checkbox-form-builder/checkbox-form-builder.component'
import { JourneyComponent } from './pages/journey/journey.component'
import { NgApexchartsModule } from 'ng-apexcharts'
import { GoogleChartsModule } from 'angular-google-charts'
import { NgSelect2Module } from 'ng-select2'
import { ImageCropperModule } from 'ngx-image-cropper'
import { ModalCustomComponent } from './components/modals/modal-custom/modal-custom.component'
import { PersonalDataFormComponent } from './components/form-builder/personal-data-form/personal-data-form.component'
import { ModalStatusComponent } from './components/modals/modal-status/modal-status.component'
import { FormFieldsComponent } from './components/form-fields/form-fields.component'
import { FormPillarsComponent } from './components/form-pillars/form-pillars.component'
import { BoardComponent } from './components/board/board.component'
import { FeedbackComponent } from './components/feddback/feedback.component';
import { ModalEmailComponent } from './components/modals/modal-email/modal-email.component'

const ngWizardConfig: NgWizardConfig = {
    theme: THEME.default,
}

@NgModule({
    declarations: [
        AppComponent,
        LoadingGlobalComponent,
        LoadingLocalComponent,
        LoadingProgressComponent,
        InputComponent,
        CheckboxComponent,
        CheckboxCustomComponent,
        CheckboxFormBuilderComponent,
        SelectComponent,
        RadiosComponent,
        ControlErrorComponent,
        ModalDefaultComponent,
        VersionComponent,
        NotFoundComponent,
        SetValueDirective,
        SetFormControlErrorDirective,
        FormBuilderComponent,
        ControlsFormBuilderComponent,
        JourneyComponent,
        ModalCustomComponent,
        PersonalDataFormComponent,
        ModalStatusComponent,
        FormFieldsComponent,
        FormPillarsComponent,
        BoardComponent,
        FeedbackComponent,
        ModalEmailComponent,
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        CommonModule,
        HttpClientModule,
        LazyLoadImageModule,
        StoreModule.forRoot({ ...stateReducers }, {}),
        NgbModule,
        FormsModule,
        ReactiveFormsModule,
        NgApexchartsModule,
        GoogleChartsModule,
        NgxMaskModule.forRoot(),
        ToastrModule.forRoot({
            positionClass: 'toast-top-right',
        }),
        NgWizardModule.forRoot(ngWizardConfig),
        NgSelect2Module,
        ImageCropperModule,
        DndModule,
    ],
    providers: [
        Meta,
        // {
        // 	provide: HTTP_INTERCEPTORS,
        // 	useClass: AuthInterceptorService,
        // 	multi: true,
        // },
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}
