import { ActionUserTypes } from './user.action'
import { ActionModel } from '../action'
import { User } from '../../models/user'

const initialState: User = {
	id: '',
	needUpdatePassword: false,
	address: '',
	email: '',
	file: '',
	first_name: '',
	last_name: '',
	phone: '',
	companies: '',
}

export function userReducer(state: User = initialState, action: ActionModel) {
	switch (action.type) {
		case ActionUserTypes.Add: {
			state = { ...action.payload }
			return state
		}

		case ActionUserTypes.Clear: {
			state = initialState
			return state
		}

		default:
			return state
	}
}
