<div class="modal-body">
	<h3 *ngIf="title">{{ title }}</h3>
	<p *ngIf="desc">{{ desc }}</p>
</div>
<div class="modal-footer">
	<button type="button" class="btn btn-white me-2" (click)="close()">
		Cancelar
	</button>
	<button
		type="button"
		class="btn btn-xs btn-primary-ghr"
		(click)="confirmation()"
	>
		Confirmar
	</button>
</div>
