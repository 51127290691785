import { Component, OnInit } from '@angular/core'
import { Pillars } from '../../models/pillars'
import { ActivatedRoute, Router } from '@angular/router'
import { NgbModal } from '@ng-bootstrap/ng-bootstrap'
import { JourneyService } from '../../services/journey/journey.service'
import { ModalStatusComponent } from '../../components/modals/modal-status/modal-status.component'
import { Store } from '@ngrx/store'
import { AppState } from '../../state/app-state'
import { HiddenLoading, ShowLoading } from '../../state/loading/loading.action'
import { ModalEmailComponent } from '../../components/modals/modal-email/modal-email.component'
import { UserStorage } from '../../models/userStorage'
import { HelpersService } from '../../services/helpers/helpers.service'

@Component({
    selector: 'app-quiz',
    templateUrl: './journey.component.html',
    styleUrls: ['./journey.component.scss']
})
export class JourneyComponent implements OnInit {
    slug: string = this.route.snapshot.params.slug

    currentPillar: Pillars | null = null

    loading: boolean = false
    loadingPersonalData: boolean = false

    _reload: boolean = true
    answerQuestionnaire: boolean = false
    finished: boolean = false

    currentStep: number = 1
    currentQuestion: number = 1
    percentPillar: number = 0

    // ---------
    listQuiz: any[] = []
    results: any = null
    keyEncryptUser: string = btoa('personalData-' + this.slug)
    currentUrl: string = ''
    step: string = 'personalData'

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private journeyService: JourneyService,
        private modal: NgbModal,
        private store: Store<AppState>,
        private helpers: HelpersService
    ) {
    }

    ngOnInit(): void {
        this.currentUrl = window.location.href
        this.getQuiz(this.slug)
    }

    setCurrentPillar(pillar: Pillars | null): void {
        this.currentPillar = pillar
        this.percentPillar = 0
        this.currentQuestion = 1
        this.setPercentInPillar()
        this.reload()
    }

    setPercentInPillar() {
        if (this.currentPillar) {
            const percent = 100 / this.currentPillar?.field.length
            if (this.percentPillar < 100) {
                this.percentPillar = this.percentPillar + percent
            }
        }
    }

    setPercentInPillarBack() {
        if (this.currentPillar) {
            const percent = 100 / this.currentPillar?.field.length
            if (this.percentPillar > 0) {
                this.percentPillar = this.percentPillar - percent
            }
        }
    }

    setQuestionnaire() {
        this.answerQuestionnaire = true
    }

    openModal(
        title: string,
        desc: string,
        type: string,
        btn: string = 'Continuar',
        idForm: string = ''
    ) {
        const modalRef = this.modal.open(ModalStatusComponent)
        modalRef.componentInstance.title = title
        modalRef.componentInstance.desc = desc
        modalRef.componentInstance.type = type
        modalRef.componentInstance.btnName = btn
        modalRef.componentInstance.idForm = idForm
    }

    toggleLoading(type: string) {
        setTimeout(() => {
            this.store.dispatch(
                type == 'show' ? ShowLoading() : HiddenLoading()
            )
        })
    }

    getQuiz(id: string): void {
        const self = this

        self.journeyService.getQuiz(id, {
            fnSuccess(response) {
                const { data } = response
                self.listQuiz = data.pillar
                window.dataLayer.push({
                    event: 'GetJourney',
                    eventCategory: 'request',
                    eventAction: 'get',
                    eventLabel: 'Carregar jornada'
                })
            },
            fnFinalized() {
                self.loading = false
                self.loadingPersonalData = false
            },
            fnError() {
                self.openModal('Ops!', 'Algo deu errado!', 'error')
                self.router.navigate(['/404'])
            }
        })
    }

    changeStep(nextStep: string): void {
        if (nextStep === 'personalData' && this.checkHasSession()) {
            nextStep = this.getResultsUserStorage() ? 'results' : 'listPillar'
        }
        if (nextStep === 'listPillar') {
            this.currentPillar = null
        }
        this.step = nextStep
    }

    shareWhatsapp(): void {
        window.dataLayer.push({
            event: 'SharedWhatsapp',
            eventCategory: 'request',
            eventAction: 'click',
            eventLabel: 'Compartilhar Whatsapp'
        })
        window.open(`whatsapp://send?text=${this.currentUrl + '?origin=whatsapp'}`)
    }

    openModalInviteEmail(): void {
        window.dataLayer.push({
            event: 'SharedEmail',
            eventCategory: 'request',
            eventAction: 'click',
            eventLabel: 'Compartilhar e-mail'
        })
        const self = this
        const modalRef = this.modal.open(ModalEmailComponent)
        modalRef.componentInstance.slug = this.slug
        modalRef.componentInstance.session = this.getSessionUserStorage()
        modalRef.componentInstance.onError = () => {
            self.openModal('Ops!', 'Algo deu errado!', 'error')
        }
    }

    savePersonalData(data: any): void {
        const self = this
        let personalData: UserStorage = data
        self.toggleLoading('show')

        self.journeyService.saveDataUser(this.slug, personalData, {
            fnSuccess(res) {
                personalData = {
                    ...personalData,
                    ...res.data,
                    pillarsAnswered: [],
                    results: null
                }
                if (res.data.completed) {
                    self.openModal('Ops!', 'Você já preencheu esse formulário!', 'error')
                    self.changeStep('welcome')
                    localStorage.removeItem(self.keyEncryptUser)
                } else {
                    self.changeStep('listPillar')
                    window.dataLayer.push({
                        event: 'SendPersonalData',
                        eventCategory: 'request',
                        eventAction: 'submit',
                        eventLabel: 'Enviou os dados pessoais'
                    })
                    window.dataLayer.push({
                        event: 'ListPillar',
                        eventCategory: 'request',
                        eventAction: 'submit',
                        eventLabel: 'Lista de pilares'
                    })
                    localStorage.setItem(self.keyEncryptUser, btoa(JSON.stringify(personalData)))
                }
            },
            fnFinalized() {
                self.toggleLoading('hide')
            },
            fnError() {
                self.openModal('Ops!', 'Algo deu errado!', 'error')
            }
        })
    }

    checkHasSession(): boolean {
        return !!localStorage.getItem(this.keyEncryptUser)
    }

    getUserStorage(): any {
        if (localStorage.getItem(this.keyEncryptUser)) {
            return JSON.parse(atob(localStorage.getItem(this.keyEncryptUser) || '') || '')
        }
    }

    getSessionUserStorage(): string {
        return this.getUserStorage().session
    }

    getPillarsUserStorage(): string {
        return this.getUserStorage().pillarsAnswered
    }

    getResultsUserStorage(): any {
        return this.getUserStorage().results
    }

    setPillarsUserStorage(idPillar: string): void {
        const userStorage = this.getUserStorage()
        userStorage.pillarsAnswered.push(idPillar)
        localStorage.setItem(this.keyEncryptUser, btoa(JSON.stringify(userStorage)))
    }

    checkPillarsUserStorage(idPillar: string): boolean {
        return this.getUserStorage().pillarsAnswered.length && this.getUserStorage().pillarsAnswered.includes(idPillar)
    }

    reload() {
        setTimeout(() => (this._reload = false))
        setTimeout(() => (this._reload = true))
    }

    choosePillar(slugPillar: string) {
        const pillar = this.listQuiz.find((pillar) => pillar.slug === slugPillar)
        if (this.checkPillarsUserStorage(pillar.id)) {
            this.changeStep('alreadyAnswered')
        } else {
            this.changeStep('questions')
        }
        this.currentStep = 1
        this.answerQuestionnaire = false
        this.setCurrentPillar(pillar)
    }

    backPage(event: any) {
        if (event < 1) {
            this.answerQuestionnaire = false
        } else {
            this.currentQuestion = event
            this.currentStep = event
        }

        this.setPercentInPillarBack()
    }

    nextPage(event: any) {
        this.currentQuestion = event
        this.currentStep = event
        this.setPercentInPillar()
    }

    onSubmitPillar(event: any) {
        const self = this
        const currentPillar: Pillars | any = self.currentPillar ? self.currentPillar : ''
        if (currentPillar && currentPillar.slug) {
            const data: any = {
                session: self.getSessionUserStorage(),
                field: {
                    id: event.id,
                    value: event.event
                }
            }

            self.journeyService.savePillarJourney(self.slug, currentPillar.slug, data, {
                fnSuccess(res) {
                    if (res.data.results) {
                        self.results = self.helpers.lowerCaseKeyOfObject(res.data.results)
                        window.dataLayer.push({
                            event: 'results',
                            eventCategory: 'request',
                            eventAction: 'open',
                            eventLabel: 'Resultados'
                        })
                    } else {
                        window.dataLayer.push({
                            event: 'sendResponse',
                            eventCategory: 'request',
                            eventAction: 'submit',
                            eventLabel: event.id
                        })
                    }
                    const totalPillars = self.listQuiz.length

                    if (self.percentPillar >= 100) {
                        self.setPillarsUserStorage(currentPillar.id)
                        self.changeStep('finished')
                        window.dataLayer.push({
                            event: 'finishedPillar',
                            eventCategory: 'request',
                            eventAction: 'submit',
                            eventLabel: currentPillar.title
                        })
                        setTimeout(() => {
                            if (self.getPillarsUserStorage().length === totalPillars) {
                                self.changeStep('results')
                                localStorage.removeItem(self.keyEncryptUser)
                            } else {
                                self.changeStep('listPillar')
                            }

                        }, 2000)
                    } else {
                        self.currentStep = self.currentStep + 1
                    }
                    self.setPercentInPillar()
                    self.currentQuestion = self.currentStep
                    window.scrollTo({ top: 0, behavior: 'smooth' })
                },
                fnFinalized() {
                    self.loading = false
                },
                fnError() {
                    self.openModal('Ops!', 'Algo deu errado!', 'error')
                }
            })
        }
    }

    convertObjToArray(obj: any): any {
        return Object.values(obj)
    }

    restartFlow(): void {
        localStorage.removeItem(this.keyEncryptUser)
        window.location.reload()
    }
}
