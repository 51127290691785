const api = 'https://rascovski-cerebro-acao-back.valide.vc/api'

export const environment = {
	api: `${api}`,
	redirects: {
		roles: {
			admin: '/dashboard',
			public: '/',
		},
	},
	env: 'hml',
	production: false,
}
