import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'

//components
import { NotFoundComponent } from './pages/not-found/not-found.component'
import { JourneyComponent } from './pages/journey/journey.component'

const routes: Routes = [
    {
        path: '404',
        component: NotFoundComponent,
        data: { title: 'Página não encontrada' },
    },
	{
		path: ':slug',
		component: JourneyComponent,
		data: {
			title: 'Jornada',
		},
	},
	{
		path: '**',
		redirectTo: '/404',
		data: { title: 'Página não encontrada' },
	},
]

@NgModule({
	imports: [RouterModule.forRoot(routes)],
	exports: [RouterModule],
})
export class AppRoutingModule {}
