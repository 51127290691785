<div class="control select">
	<div class="form-group">
		<div [class.default]="!labelName" [formGroup]="form">
			<label [for]="id || name" *ngIf="labelName" class="form-label">
				{{ labelName }}
			</label>

			<select
				#element
				[id]="id || name"
				[attr.name]="name"
				[formControlName]="name"
				[setValue]="inputModel"
				(change)="onChange(element.value)"
				type="text"
				class="form-control select-custom-nav"
				aria-label="Select example"
				[attr.disabled]="disabled || null"
			>
				<option value="" [ngValue]="null" hidden>{{ placeholder }}</option>
				<option value="" [ngValue]="''" hidden>{{ placeholder }}</option>
				<option *ngFor="let opt of options" [value]="opt.value">
					{{ opt.label }}
				</option>
			</select>
		</div>

		<app-control-error
			[form]="form"
			[name]="name"
			[labelName]="labelName"
		></app-control-error>
	</div>
</div>
