<div
    class="form-check form-check-custom form-check-solid"
    [class]="'form-check-' + typeCheck"
>
    <div class="my-4" [formGroup]="form">
        <div class="form-check p-0">
            <input
                class="form-check-input"
                type="checkbox"
                [id]="'c-' + id || name"
                [attr.name]="name"
                [attr.disabled]="disabled ? true : null"
                [value]="value"
                (change)="fnChangeModel($event)"
            />
            <label class="form-check-label" [for]="'c-' + id || name">
                {{ labelName }}
                <ng-content></ng-content>
            </label>
        </div>
    </div>
</div>
