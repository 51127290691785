import { AbstractControl, ValidationErrors } from '@angular/forms'
import * as moment from 'moment/moment'

function addMaskDate(value: string): string | Date {
    if (value) {
        let v = value.replace(/\D/g, '').slice(0, 10)
        if (v.length >= 5) {
            return `${v.slice(0, 2)}/${v.slice(2, 4)}/${v.slice(4)}`
        } else if (v.length >= 3) {
            return `${v.slice(0, 2)}/${v.slice(2)}`
        }
        return v
    }
    return ''
}

export class GenericValidatorService {
    constructor() {
    }

    static isValidCpf(control: AbstractControl): ValidationErrors | null {
        const { value } = control
        if (value) {
            if (value === undefined) return { cpfInvalid: true }
            const cpf = value.replace(/[^\d]+/g, '')
            let Soma
            let Resto
            Soma = 0
            if (
                cpf.length !== 11 ||
                cpf === '00000000000' ||
                cpf === '11111111111' ||
                cpf === '22222222222' ||
                cpf === '33333333333' ||
                cpf === '44444444444' ||
                cpf === '55555555555' ||
                cpf === '66666666666' ||
                cpf === '77777777777' ||
                cpf === '88888888888' ||
                cpf === '99999999999'
            )
                return { cpfInvalid: true }

            for (let i = 1; i <= 9; i++)
                Soma += parseInt(cpf.substring(i - 1, i)) * (11 - i)
            Resto = (Soma * 10) % 11

            if (Resto === 10 || Resto === 11) Resto = 0

            if (Resto !== parseInt(cpf.substring(9, 10)))
                return { cpfInvalid: true }

            Soma = 0

            for (let i = 1; i <= 10; i++)
                Soma += parseInt(cpf.substring(i - 1, i)) * (12 - i)
            Resto = (Soma * 10) % 11

            if (Resto === 10 || Resto === 11) Resto = 0

            if (Resto !== parseInt(cpf.substring(10, 11)))
                return { cpfInvalid: true }
        }
        return null
    }

    static validatePhone(control: AbstractControl): ValidationErrors | null {
        let { value } = control
        if (value) {
            value = value.replace(/\D/g, '')
            if (!(value.length >= 10 && value.length <= 11)) {
                return { phoneInvalid: true }
            }
            if (value.length === 11 && parseInt(value.substring(2, 3)) !== 9) {
                return { phoneInvalid: true }
            }
            const codigosDDD = [11, 12, 13, 14, 15, 16, 17, 18, 19, 21, 22, 24, 27, 28, 31, 32, 33, 34, 35, 37, 38, 41, 42, 43, 44, 45, 46, 47, 48, 49, 51, 53, 54, 55, 61, 62, 63, 64, 65, 66, 67, 68, 69, 71, 73, 74, 75, 77, 79, 81, 82, 83, 84, 85, 86, 87, 88, 89, 91, 92, 93, 94, 95, 96, 97, 98, 99]
            if (!codigosDDD.includes(parseInt(value.substring(0, 2)))) {
                return { phoneInvalid: true }
            }
        }

        return null
    }

    static checkPasswords(control: AbstractControl): ValidationErrors | null {
        return !!control.parent &&
        !!control.parent.value &&
        // @ts-ignore
        control.value === control?.parent?.controls['password']?.value
            ? null
            : { notSamePassword: false }
    }

    static validateDate(control: AbstractControl): ValidationErrors | null {
        let { value } = control

        if (value && value.length >= 8) {
            const day = parseInt(value.substring(0, 2), 10)
            const month = parseInt(value.substring(2, 4), 10)
            const year = parseInt(value.substring(4, 8), 10)

            if (year < 1900 || year > 3000 || month === 0 || month > 12) {
                return { dateInvalid: true }
            }

            const monthLength = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31]

            if (year % 400 === 0 || (year % 100 !== 0 && year % 4 === 0)) {
                monthLength[1] = 29
            }

            if (!(day > 0 && day <= monthLength[month - 1])) {
                return { dateInvalid: true }
            }
        } else {
            return { dateInvalid: true }
        }

        return null
    }

    static validateMore18(control: AbstractControl): ValidationErrors | null {
        let { value } = control
        if (value && value.length >= 8) {
            const birthday = moment(addMaskDate(value), 'DD.MM.YYYY')
            const age = moment().diff(birthday, 'years')

            if (age < 18) {
                return { dateBirthdayInvalid: true }
            }
        }

        return null
    }
}
