<div class="wrapper-form">
	<h3 class="form-title" *ngIf="!hasField">Adicionar nova pergunta</h3>
	<h3 class="form-title" *ngIf="hasField">Editar pergunta</h3>
	<form
		action="#"
		autocomplete="off"
		(ngSubmit)="onSubmit()"
		[formGroup]="form"
	>
		<div class="mb-12">
			<app-select
				id="type"
				name="type"
				[options]="optionsTypes"
				labelName="Tipo de pergunta"
				placeholder="Selecione"
				[inputModel]="hasField?.type"
				[form]="form"
				(output)="changedSelectTypes($event)"
				[disabled]="hasField"
			></app-select>
		</div>
		<div class="mb-12" *ngIf="this.form.value.type">
			<app-input
				id="label"
				name="label"
				typeInput="text"
				placeholder="A qual evento você foi?"
				labelName="Pergunta"
				[inputModel]="hasField?.label"
				[form]="form"
			></app-input>
		</div>

		<div
			class="mb-12"
			*ngIf="typeInputOnCreat === 'text' || typeInputOnCreat === 'select'"
		>
			<app-input
				id="placeholder"
				name="placeholder"
				typeInput="text"
				placeholder="Placeholder field"
				labelName="Placeholder field"
				[inputModel]="hasField?.placeholder"
				[form]="form"
			></app-input>
		</div>

		<div
			*ngIf="
				typeInputOnCreat === 'select' ||
				typeInputOnCreat === 'checkbox' ||
				typeInputOnCreat === 'radio'
			"
		>
			<!-- <label for="Options" class="form-label ps-12"> Opções </label> -->
			<div formArrayName="itemOptions" id="Options">
				<div
					*ngFor="
						let quantity of itemOptions().controls;
						let i = index
					"
					[formGroupName]="i"
					class="mb-12"
				>
					<div class="list-options">
						<div class="field">
							<input
								type="text"
								formControlName="label"
								class="form-control"
								placeholder="option name"
								[setValue]="
									hasField ? hasField?.options[i]?.label : ''
								"
							/>
						</div>
						<div class="field">
							<select
								class="form-control select-custom-nav"
								name="Score"
								formControlName="score"
								[setValue]="
									hasField ? hasField?.options[i]?.score : ''
								"
							>
								<option value="">##</option>
								<option
									*ngFor="let score of selectScore"
									[value]="score.value"
								>
									{{ score.label }}
								</option>
							</select>
						</div>
						<button
							(click)="removeQuantity(i)"
							[disabled]="hasOpt <= 1"
						>
							<img
								src="/assets/images/trash.svg"
								alt="Remover"
								title="Remover"
							/>
						</button>
					</div>
				</div>
			</div>
			<!-- <div class="wrapper-button px-12">
				<button type="button" (click)="addQuantity()" class="btn">
					Adicionar opção
				</button>
			</div> -->
		</div>

		<div class="wrapper-button d-flex justify-content-end w-100 pt-10 pb-5">
			<button
				type="submit"
				class="btn btn-primary-ghr me-4"
				[disabled]="form.invalid"
			>
				Salvar
			</button>
		</div>
	</form>
</div>
