<div class="main-container">
	<!-- <app-drag-drop>
		
	</app-drag-drop> -->
	   <div class='container'>
	       <div class='wrapper-not-found'>
	           <div>
	               <span>404</span>
	           </div>
	       </div>
	       <h2>Parece que não conseguimos encontrar o que você procura</h2>
	       <p>A página que você está procurando não existe ou foi movida</p>
	       <button class="custom-button" (click)="backToPage()"><img src="../../../assets/images/white-arrow.png" alt=""> Voltar</button>
	   </div>
</div>
