import { Injectable } from '@angular/core'
import * as moment from 'moment'
import jwtDecode from 'jwt-decode'
import { VersionService } from '../version/version.service'

@Injectable({
    providedIn: 'root'
})
export class HelpersService {
    constructor(private versionService: VersionService) {
    }

    private controlMessageError(typeError: string, control?: string): string {
        switch (typeError) {
            case 'required':
                return `O campo ${control} é obrigatório!`
            case 'email':
                return `E-mail inválido!`
            case 'cpfInvalid':
                return `CPF inválido!`
            case 'dateInvalid':
                return `Data inválida!`
            case 'dateBirthdayInvalid':
                return `Inválido, menor de 18 anos!`
            case 'phoneInvalid':
                return `Telefone inválido!`
            case 'notSamePassword':
                return `Senha e Confirmar Senha não são iguais!`
            default:
                return ''
        }
    }

    public getControlMessageError(objError: any, control?: string): string {
        const typeError = Object.keys(objError).length
            ? Object.keys(objError)[0]
            : ''
        return this.controlMessageError(typeError, control)
    }

    public converterSnakeToCamelCase(obj: any): any {
        let newObj: any = {}

        for (let d in obj) {
            if (obj.hasOwnProperty(d)) {
                newObj[
                    d.replace(/(\_\w)/g, (k) => {
                        return k[1].toUpperCase()
                    })
                    ] = obj[d]
            }
        }
        return newObj
    }

    public converterCamelCaseToSnake(obj: any): any {
        let newObj: any = {}

        for (let d in obj) {
            if (obj.hasOwnProperty(d)) {
                newObj[this.camelToUnderscore(d)] = obj[d]
            }
        }
        return newObj
    }

    public camelToUnderscore(key: string): string {
        const result = key.replace(/([A-Z])/g, ' $1')
        return result.split(' ').join('_').toLowerCase()
    }

    decodePayloadJWT(token: string): any {
        try {
            return jwtDecode(token)
        } catch (Error) {
            return null
        }
    }

    public getDuration(date: Date | string): string {
        const end = moment()
        const init = moment.utc(date).local()
        const duration = moment.duration(end.diff(init))
        const durationYears = duration.years()
        const durationMonths = duration.months()
        const durationDays = duration.days()
        const durationHours = duration.hours()
        const durationMinutes = duration.minutes()
        let msg = `__YEARS____MONTHS____DAYS____HOURS____MINUTES__`

        const msgYear = durationYears ? durationYears + 'ano(s) ' : ''
        const msgMonth = durationMonths ? durationMonths + 'mês(es) ' : ''
        const msgDays = durationDays ? durationDays + 'd ' : ''
        const msgHours = durationHours ? durationHours + 'h ' : ''
        const msgMinutes = durationMinutes ? durationMinutes + 'm ' : '0m'

        if (date) {
            return msg
                .replace('__YEARS__', msgYear)
                .replace('__MONTHS__', msgMonth)
                .replace('__DAYS__', msgDays)
                .replace('__HOURS__', msgHours)
                .replace('__MINUTES__', msgMinutes)
        }
        return '-- --'
    }

    public getDateToAge(date: Date | string): string {
        if (!date) return '--'
        return `${moment().diff(moment.utc(date).local(), 'years')}`
    }

    public getDate(date: Date | string, withHours: boolean = false): string {
        if (!date) return '--'
        let format = withHours ? 'DD/MM/YYYY -- HH:mm' : 'DD/MM/YYYY'
        let dateString = moment.utc(date).local().format(format)
        return dateString.replace('--', 'às')
    }

    public converterTsInDate(
        date: Date | string,
        isTs: boolean = false
    ): string {
        if (!date) return '--'
        const today = moment()
        const yesterday = moment().add(-1, 'days')
        let newDate = moment.utc(date).local()
        if (isTs) {
            newDate = moment.utc(date, 'X').local()
        }
        let dateString = `${newDate.format('DD/MM/YYYY -- HH:mm')}`

        if (newDate.format('DD/MM/YYYY') === today.format('DD/MM/YYYY')) {
            dateString = `hoje ${newDate.format('-- HH:mm')}`
        } else if (
            newDate.format('DD/MM/YYYY') === yesterday.format('DD/MM/YYYY')
        ) {
            dateString = `ontem ${newDate.format('-- HH:mm')}`
        }

        return dateString.replace('--', 'às')
    }

    public scrollToElement($element: any): void {
        $element.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
            inline: 'nearest'
        })
    }

    public getQueryVariable(variable: string): string {
        const query = window.location.search.substring(1)
        const vars = query.split('&')
        for (let i = 0; i < vars.length; i++) {
            const pair = vars[i].split('=')
            if (pair[0] == variable) return pair[1]
        }
        return ''
    }

    public transformNumber(n: string, toFixed = 2) {
        return (Math.round(parseFloat(n) * 100) / 100).toFixed(toFixed)
    }

    public transformString(value: any, valueDefault: string = ''): string {
        return value !== null && value !== undefined ? `${value}` : valueDefault
    }

    public removeNullValuesFromObject(obj: any) {
        Object.keys(obj).forEach((key) => {
            if (obj[key] === null || obj[key] === undefined) {
                delete obj[key]
            }
        })

        return obj
    }

    public clearLocalStorage(currentVersion: string) {
        let version = localStorage.getItem('version') || '0.0.0'

        currentVersion = currentVersion.split('=')[1]
        currentVersion = currentVersion.replace('\n', '')

        if (version !== currentVersion) {
            localStorage.clear()
            localStorage.setItem('version', currentVersion)

            //@ts-ignore
            window.location = '/login'
        }
    }

    public setDevicesInList(
        mediaDevices: MediaDeviceInfo[],
        list: any[],
        kind: string
    ): void {
        let count = 1
        mediaDevices.forEach((mediaDevice) => {
            if (mediaDevice.kind === kind) {
                list.push({
                    label:
                        mediaDevice.label ||
                        `${kind.toLocaleLowerCase()} ${count}`,
                    value: mediaDevice.deviceId
                })
                count++
            }
        })
    }

    public addMaskCpf(v: string) {
        if (!v) return '--'
        v = v.replace(/\D/g, '')
        v = v.replace(/(\d{3})(\d)/, '$1.$2')
        v = v.replace(/(\d{3})(\d)/, '$1.$2')
        v = v.replace(/(\d{3})(\d{1,2})$/, '$1-$2')
        return v
    }

    public removeBlankAttributesFromObject(obj: any) {
        Object.keys(obj).forEach(
            (k: string | number) => obj[k] == null && delete obj[k]
        )
    }

    public parseNumber(number: any, country: string) {
        if (number.length) {
            let USNumber = number.match(/(\d{2})(\d{5})(\d{4})/)
            if (country) {
                switch (country) {
                    case '+1':
                        USNumber = number.match(/(\d{1})(\d{5})(\d{5})/)
                        break
                    case '+55':
                        USNumber = number.match(/(\d{2})(\d{5})(\d{4})/)
                        break
                    case '+34':
                        USNumber = number.match(/(\d{2})(\d{5})(\d{4})/)
                        break
                }
            }
            USNumber =
                '(' + USNumber[1] + ') ' + USNumber[2] + '-' + USNumber[3]
            return USNumber
        }
        return '--'
    }

    reduceText(text: string = '', value: number = 0) {
        let textReduced = text
        if (text != '' && text.length > value) {
            for (let i = value - 1; i < text.length; i++) {
                if (text[i] == ' ') {
                    textReduced = text.substring(0, i)
                    textReduced = textReduced + '...'
                    return textReduced
                }
            }
            return textReduced
        } else return textReduced
    }

    encodeImageFileAsURL(url: any, callback: any) {
        var xhr = new XMLHttpRequest()
        xhr.onload = () => {
            var reader = new FileReader()
            reader.onloadend = () => {
                callback(reader.result)
            }
            reader.readAsDataURL(xhr.response)
        }
        xhr.open('GET', url)
        xhr.responseType = 'blob'
        xhr.send()
    }

    async getBase64ImageFromUrl(imageUrl: any) {
        const res = await fetch(imageUrl)
        const blob = await res.blob()

        return await new Promise<any>((resolve, reject) => {
            const reader = new FileReader()
            reader.addEventListener(
                'load',
                () => {
                    resolve(reader.result)
                },
                false
            )

            reader.onerror = () => {
                return reject(this)
            }
            reader.readAsDataURL(blob)
        })
    }

    getActualDate() {
        const monthNames = [
            'Janeiro',
            'Fevereiro',
            'Março',
            'Abril',
            'Maio',
            'Junho',
            'Julho',
            'Agosto',
            'Setembro',
            'Outubro',
            'Novembro',
            'Dezembro'
        ]

        function dateFormat1(d: any) {
            const t = new Date(d)
            return (
                (t.getDate() < 10 ? '0' + t.getDate() : t.getDate()) +
                ' de ' +
                monthNames[t.getMonth()] +
                ' de ' +
                t.getFullYear()
            )
        }

        return dateFormat1(new Date())
    }

    lowerCaseKeyOfObject(obj: any): any {
        return Object.keys(obj).reduce((acc: any, k: any) => {
            acc[k.toLowerCase()] = obj[k]
            return acc
        }, {})
    }

    public transformDateUS(date: Date | string): string {
        if (!date) return moment().format('YYYY-MM-DD')
        return moment(date, 'DD/MM/YYYY').format('YYYY-MM-DD')
    }

    public addMaskDate(value: string) {
        if (value) {
            let v = value.replace(/\D/g, '').slice(0, 10)
            if (v.length >= 5) {
                return `${v.slice(0, 2)}/${v.slice(2, 4)}/${v.slice(4)}`
            } else if (v.length >= 3) {
                return `${v.slice(0, 2)}/${v.slice(2)}`
            }
            return v
        }
        return ''
    }
}
